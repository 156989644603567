import { useStaticQuery, graphql } from "gatsby"
import React, { useState } from "react"
import AnchorLink from 'react-anchor-link-smooth-scroll'

const Header = () => {
  let [ active, setActive ] = useState('');
  let [ hamburgerToogle, setHamburgerToogle ] = useState(false);


  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: {eq: "logo.png"}) {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  `)

   let menu = ['O nas', 'O produkcie', 'Formularz zapytania']
   
   return (
      <header>
        <nav>

            <div className="logo">
                <img src={ data.logo.childImageSharp.original.src } alt=""/>
            </div>

            <div className="menu">
              <ul className={ hamburgerToogle ? 'open' : null}>
                { menu.map( el => (
                  <li key={ el } className={ active === el ? 'active' : null}>
                    <AnchorLink href={ '#' + el.toLowerCase().replace(' ', '-') } onClick={ () => setActive( el )}>{ el }</AnchorLink>
                  </li>
                ))}
              </ul>

              <div className="hamburger-wrap">
                <div className={ "hambg" + ( hamburgerToogle ? ' open' : '')} onClick={ () => setHamburgerToogle(!hamburgerToogle) }>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
              </div>

              <div className="phone">
                <a href="tel:+48 665 920 944">+48 665 920 944</a>
              </div>
            </div>
            
        </nav>
    </header>
  )
}
 

export default Header

